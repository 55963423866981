import { INoteRead } from "@features/note/noteTypes";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export interface INoteViewerProps {
  note: INoteRead;
}

const NoteViewer = ({ note }: INoteViewerProps) => (
  <Box
    sx={{
      bgcolor: "background.paper",
      px: 4,
      borderRadius: 1,
      height: "100%",
      overflow: "auto",
    }}
  >
    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: note.body_html }} />
  </Box>
);

export default NoteViewer;
