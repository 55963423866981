import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { Delete as DeleteIcon, FolderOutlined } from "@mui/icons-material";
import { IconButton, Stack, styled, TableCell, TableRow, Typography } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import { useMultiDrag, useMultiDrop, usePreview } from "react-dnd-multi-backend";
import { useTranslation } from "react-i18next";
import EditFileFormDialog from "./EditFileFormDialog";
import { IStorageFileRead } from "./storageFileTypes";
import {
  DraggableItemTypes,
  getItemName,
  getNewDirectoryPathWhenMoving,
  getNewFilePathWhenMoving,
  isDirectory,
} from "./utils";

interface StyledRowProps {
  isDragging: boolean;
  isOver: boolean;
  canDrop: boolean;
  canDrag: boolean;
}

const StyledRow = styled(TableRow)<StyledRowProps>(({ isDragging, isOver, canDrop, canDrag }) => ({
  cursor: canDrag ? (isOver && !canDrop ? "no-drop" : isDragging ? "grab" : "grabbing") : "default",
  opacity: isDragging ? 0.5 : 1,
  ":active": {
    cursor: canDrag ? "grabbing" : "default",
  },
  ...(isOver && {
    backgroundColor: "rgba(25, 118, 210, 0.1)",
  }),
}));

const StyledCell = styled(TableCell)(() => ({
  maxWidth: 240,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

interface DirectoryItemProps {
  canDrag?: boolean;
  deleteDirectory: (directory: IStorageFileRead) => void;
  directory: IStorageFileRead;
  enterDirectory: (directory: IStorageFileRead) => void;
  findFile: (id: string) => { file: IStorageFileRead; index: number };
  isLoading?: boolean;
  moveFile: (file: IStorageFileRead, newPath: string) => Promise<void>;
}

interface Item {
  id: string;
  originalIndex: number;
}

const DirectoryItem: React.FC<DirectoryItemProps> = memo(
  ({
    canDrag = false,
    deleteDirectory: deleteDirectoryProps,
    enterDirectory: enterDirectoryProps,
    directory,
    findFile,
    isLoading = false,
    moveFile,
  }) => {
    const { can } = useAuthorization();
    const { t } = useTranslation();
    const preview = usePreview();

    const enterDirectory = useCallback(() => enterDirectoryProps(directory), [enterDirectoryProps, directory]);
    const deleteDirectory = useCallback(() => deleteDirectoryProps(directory), [deleteDirectoryProps, directory]);

    const originalIndex = useMemo(() => findFile(directory.id.toString()).index, [directory.id, findFile]);

    const canUpdateFile = can("UPDATE", "FILE");

    const [[{ isDragging }, drag]] = useMultiDrag({
      type: DraggableItemTypes.File,
      item: { id: directory.id.toString(), originalIndex },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
      canDrag: !isLoading && canUpdateFile && canDrag,
    });

    const [[{ isOver, canDrop }, drop]] = useMultiDrop({
      accept: [DraggableItemTypes.File, DraggableItemTypes.Directory],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      canDrop: () => canUpdateFile,
      drop: ({ id: draggedId }: Item) => {
        if (draggedId !== directory.id.toString() && canUpdateFile) {
          const { file } = findFile(draggedId);

          if (isDirectory(file.user_fullpath)) {
            moveFile(file, getNewDirectoryPathWhenMoving(file, directory));
          } else {
            moveFile(file, getNewFilePathWhenMoving(file, directory));
          }
        }
      },
    });

    return (
      <StyledRow
        hover
        canDrop={canDrop}
        isDragging={isDragging}
        isOver={isOver}
        canDrag={!isLoading && canUpdateFile && canDrag}
        ref={(elem) => (canUpdateFile ? drag(drop(elem)) : null)}
        style={{
          ...(preview.display &&
          preview.itemType === DraggableItemTypes.File &&
          (preview.item as any).id === directory.id.toString()
            ? preview.style
            : undefined),
        }}
      >
        <StyledCell>
          <Stack alignItems="center" direction="row" flexWrap="nowrap" gap={1}>
            <FolderOutlined />
            <Typography
              onClick={enterDirectory}
              sx={{
                color: "rgba(25, 118, 210, 1)",
                textDecoration: "underline",
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="body2"
            >
              {getItemName(directory.user_fullpath)}
            </Typography>
          </Stack>
        </StyledCell>
        <StyledCell></StyledCell>
        <StyledCell>
          <Typography variant="body2">{t("fileManager.type.directory")}</Typography>
        </StyledCell>
        <StyledCell>
          <Typography variant="body2">{directory.tags.map((tag) => tag.name).join(", ")}</Typography>
        </StyledCell>
        <StyledCell>
          <Typography variant="body2">{new Date(directory.updated_at).toLocaleString()}</Typography>
        </StyledCell>
        <StyledCell></StyledCell>
        <StyledCell>
          {can("UPDATE", "FILE") && can("DELETE", "FILE") && (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
              <EditFileFormDialog file={directory} />
              <IconButton onClick={deleteDirectory}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          )}
        </StyledCell>
      </StyledRow>
    );
  },
);

export default DirectoryItem;
