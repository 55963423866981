import TagSelect from "@features/tags/TagSelect";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface TagsFilterDialogProps {
  applyFilters: (tags?: string[]) => void;
  filteredTags?: string[];
  onClose: () => void;
  open: boolean;
}

const TagsFilterDialog: React.FC<TagsFilterDialogProps> = ({
  applyFilters: applyFiltersProps,
  filteredTags,
  onClose,
  open,
}) => {
  const { t } = useTranslation();

  const [selectedTags, setSelectedTags] = useState<string[]>(filteredTags ?? []);

  useEffect(() => {
    setSelectedTags(filteredTags ?? []);
  }, [filteredTags, open]);

  const applyFilters = useCallback(() => {
    applyFiltersProps(selectedTags.length > 0 ? selectedTags : undefined);
    onClose();
  }, [applyFiltersProps, onClose, selectedTags]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>{t("tags.filterDialog.title")}</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <TagSelect value={selectedTags} onChange={setSelectedTags} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("tags.filterDialog.cancel")}</Button>
        <Button color={"primary"} onClick={applyFilters} variant={"contained"}>
          {t("tags.filterDialog.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagsFilterDialog;
