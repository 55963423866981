import { useDeleteNoteMutation, useGetNotesQuery } from "@app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { SimpleSearchBar } from "@core/input";
import { Page } from "@core/layout/page";
import TagsFilterDialog from "@features/tags/TagsFilterDialog";
import { TagsList } from "@features/tags/TagsList";
import ResourceViewerModal from "@features/viewer/ResourceViewerModal";
import { DeleteOutlineOutlined, EditOutlined, FilterAlt, VisibilityOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Badge, Button, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridActionsCellItemProps, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import NoteFormDialog from "./NoteFormDialog";
import { INoteRead } from "./noteTypes";

const VideoListPage = () => {
  const { can } = useAuthorization();

  const { t } = useTranslation();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [filteredTags, setFilteredTags] = useState<string[]>(["1"]); // hard code tag id associed with video roboteco
  const [isFiltersDialogOpen, setFiltersDialogOpen] = useState(false);
  const [createNoteDialogOpen, setCreateNoteDialogOpen] = useState(false);
  const [noteToView, setNoteToView] = useState<INoteRead>();
  const [editNoteId, setEditNoteId] = useState<string | undefined>(undefined);

  const { data, isLoading } = useGetNotesQuery(
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchValue,
      tagIds: filteredTags,
    },
    { pollingInterval: 3000 },
  );
  const [triggerDelete, { isError, isSuccess, reset: resetDelete }] = useDeleteNoteMutation();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("notifications.modelDelete.success", { model: t("models.note") }), { variant: "success" });
      resetDelete();
    }
    if (isError) {
      enqueueSnackbar(t("notifications.modelDelete.failure", { model: t("models.note") }), { variant: "error" });
    }
  }, [isSuccess, isError, t, resetDelete]);

  const getIndexStatusColor = (status: string) => {
    switch (status) {
      case "QUEUED":
        return "info.main";
      case "PENDING":
        return "warning.main";
      case "COMPLETED":
        return "success.main";
      case "FAILED":
        return "error.main";
      default:
        return "text.primary";
    }
  };

  const columns = useMemo(
    (): (GridColDef & { isVisible?: boolean })[] => [
      {
        field: "id",
        headerName: t("notes.headings.id"),
        minWidth: 80,
      },
      {
        field: "title",
        headerName: t("notes.headings.title"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "tags",
        headerName: t("notes.headings.tags"),
        flex: 1,
        minWidth: 140,
        renderCell: (params) => <TagsList tags={params.row.tags} />,
        valueGetter: (params) => params.row.tags.map((tag: any) => tag.name).join(", "),
      },
      {
        field: "body",
        headerName: t("notes.headings.body"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "index_status",
        headerName: t("notes.headings.indexStatus"),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => (
          <Typography variant="body2" color={getIndexStatusColor(params.value)}>
            {t(params.value)}
          </Typography>
        ),
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 80,
        getActions: (params) =>
          [
            <GridActionsCellItem
              closeMenuOnClick
              showInMenu
              label={t("notes.view")}
              icon={<VisibilityOutlined />}
              onClick={() => setNoteToView(params.row)}
            />,
            can("UPDATE", "DEVICE") ? (
              <GridActionsCellItem
                closeMenuOnClick
                showInMenu
                label={t("notes.edit")}
                icon={<EditOutlined />}
                onClick={() => setEditNoteId(params.row.id)}
              />
            ) : undefined,
            can("DELETE", "DEVICE") ? (
              <GridActionsCellItem
                closeMenuOnClick
                showInMenu
                label={t("notes.delete")}
                icon={<DeleteOutlineOutlined />}
                onClick={() => triggerDelete(params.row.id)}
              />
            ) : undefined,
          ].filter(Boolean) as ReactElement<GridActionsCellItemProps>[],
      },
    ],
    [t, triggerDelete, can],
  );

  const actions = (
    <>
      <SimpleSearchBar onSearch={setSearchValue} size="small" />
      <Badge badgeContent={filteredTags?.length} color="primary">
        <Button startIcon={<FilterAlt />} onClick={() => setFiltersDialogOpen(true)}>
          {t("fileManager.filters")}
        </Button>
      </Badge>
      {can("CREATE", "DEVICE") && (
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          onClick={() => setCreateNoteDialogOpen(true)}
        >
          {t("notes.add")}
        </Button>
      )}
    </>
  );

  const handleApplyFilters = (tags?: string[] | undefined) => {
    // print tags to console
    console.log("tags:");
    console.log(tags);
    setFilteredTags(tags || []);
  };

  return (
    <>
      <Page title={t("notes.title")} actions={actions}>
        <DataGrid
          disableRowSelectionOnClick
          rows={data?.results ?? []}
          columns={columns.filter((column) => column.isVisible !== false)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          sx={{ flex: 1 }}
          loading={isLoading}
          paginationMode="server"
          rowCount={data?.count ?? 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Page>
      <TagsFilterDialog
        applyFilters={handleApplyFilters}
        filteredTags={filteredTags}
        open={isFiltersDialogOpen}
        onClose={() => setFiltersDialogOpen(false)}
      />
      <NoteFormDialog open={createNoteDialogOpen} onClose={() => setCreateNoteDialogOpen(false)} />
      <ResourceViewerModal open={!!noteToView} onClose={() => setNoteToView(undefined)} note={noteToView} />
      <NoteFormDialog open={!!editNoteId} onClose={() => setEditNoteId(undefined)} noteId={editNoteId} />
    </>
  );
};

export default withAuth(VideoListPage);
