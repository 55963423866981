import { useAppDispatch } from "@app/hooks";
import { ChatAuthorType, IChat, IChatMessage, Language } from "@app/services/apiTypes";
import { API_PATH, appApi, useCreateChatMessageMutation, useGetChatQuery } from "@app/services/appApi";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CHAT_UNINITIALIZED } from "./utils";

interface UseChatProps {
  relevantDeviceId?: string;
  relevantLogRecordId?: number;
}

interface UseChatReturn {
  chat?: IChat;
  chatId: number;
  isChatUninitialized: boolean;
  resetChat: () => void;
  sendMessage: (message: string) => Promise<boolean>;
  websocketOpen: boolean;
}

const getNewMessage = (chatId: number): IChatMessage => ({
  id: -1,
  body: "",
  author_type: ChatAuthorType.Agent,
  author: null,
  downvotes: 0,
  upvotes: 0,
  context_device: null,
  chat: { id: chatId },
  created_at: new Date().toISOString(),
});

export const useChat = ({ relevantDeviceId, relevantLogRecordId }: UseChatProps): UseChatReturn => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [websocketOpen, setWebsocketOpen] = useState(false);

  const [createChatMessage, { data: lastChatMessage, reset: resetChat, originalArgs }] = useCreateChatMessageMutation();

  const chatId =
    (lastChatMessage?.author_type !== ChatAuthorType.Tool && lastChatMessage?.chat.id) ||
    originalArgs?.chatId ||
    CHAT_UNINITIALIZED;

  const isChatUninitialized = chatId === CHAT_UNINITIALIZED;

  const { data: chat, refetch: refetchChat } = useGetChatQuery(chatId, { skip: isChatUninitialized });

  const openWebsocket = useCallback(
    (chatId: number) => {
      setWebsocketOpen(true);

      const lorem = localStorage.getItem("dbgLoremChat") === "true" ? "true" : "false";
      const wsBaseUrl = window.location.origin.replace(/^http/, "ws") + API_PATH;
      const token = localStorage.getItem("token");
      const ws = new WebSocket(`${wsBaseUrl}chat/${chatId}/stream?lorem=${lorem}&jwt=${token}`);

      try {
        ws.addEventListener("message", (event) => {
          if (event.data === "<SOF>") {
            // Start of file, append new fake message
            dispatch(
              appApi.util.updateQueryData("getChat", chatId, (prev) => ({
                ...prev,
                messages: [...prev.messages, getNewMessage(chatId)],
              })),
            );
          } else if (event.data === "<EOF>") {
            // End of file
            ws.close();
            setWebsocketOpen(false);
            refetchChat();
          } else {
            dispatch(
              appApi.util.updateQueryData("getChat", chatId, (prev) => ({
                ...prev,
                messages: [
                  ...prev.messages.slice(0, -1),
                  {
                    ...prev.messages[prev.messages.length - 1],
                    body: event.data,
                  },
                ],
              })),
            );
          }
        });
      } catch (error) {
        console.error("Error in cache subscription", error);
      }
    },
    [dispatch, refetchChat],
  );

  const sendMessage = useCallback(
    async (body: string) => {
      if (!relevantDeviceId) {
        return false;
      }

      const response = await createChatMessage({
        chatId: isChatUninitialized ? undefined : chatId,
        chatMessage: {
          body,
          language: i18n.language as Language,
          context_log_record_id: relevantLogRecordId,
          context_device_id: relevantDeviceId,
        },
      });

      if ("error" in response) {
        return false;
      }

      const messageChatId = response.data.author_type !== ChatAuthorType.Tool ? response.data.chat.id : chatId;

      if (messageChatId !== CHAT_UNINITIALIZED) {
        openWebsocket(messageChatId);
      }

      return true;
    },
    [
      chatId,
      createChatMessage,
      i18n.language,
      isChatUninitialized,
      openWebsocket,
      relevantDeviceId,
      relevantLogRecordId,
    ],
  );

  return useMemo(
    () => ({
      chat,
      chatId,
      isChatUninitialized,
      resetChat,
      sendMessage,
      websocketOpen,
    }),
    [chat, chatId, isChatUninitialized, resetChat, sendMessage, websocketOpen],
  );
};
