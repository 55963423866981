import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CreateDirectoryDialogProps {
  isCreatingDirectory?: boolean;
  onClose: () => void;
  onCreate: (name: string) => Promise<void>;
  open: boolean;
}

interface CreateDirectoryForm {
  name: string;
}

const CreateDirectoryDialog: React.FC<CreateDirectoryDialogProps> = ({
  isCreatingDirectory = false,
  onClose,
  onCreate,
  open,
}) => {
  const { t } = useTranslation();

  const form = useForm<CreateDirectoryForm>();

  const isValid = form.formState.isValid;

  const handleCreate = useCallback(
    async (data: CreateDirectoryForm) => {
      await onCreate(data.name);
      form.reset();
      onClose();
    },
    [form, onClose, onCreate],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={form.handleSubmit(handleCreate)}>
        <DialogTitle>{t("fileManager.createDirectoryDialog.title")}</DialogTitle>
        <DialogContent>
          <Controller
            name="name"
            rules={{ required: true, minLength: 3 }}
            control={form.control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label={t("fileManager.createDirectoryDialog.directoryName.label")}
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={!!fieldState.error && t("fileManager.createDirectoryDialog.directoryName.error")}
                sx={{ mt: 2 }}
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("fileManager.createDirectoryDialog.cancel")}</Button>
          <LoadingButton
            loading={isCreatingDirectory}
            disabled={!isValid}
            variant="contained"
            color="primary"
            type="submit"
          >
            {t("fileManager.createDirectoryDialog.create")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateDirectoryDialog;
