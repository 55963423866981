import { Box, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useMultiDrop } from "react-dnd-multi-backend";
import { useTranslation } from "react-i18next";
import DirectoryItem from "./DirectoryItem";
import FileItem from "./FileItem";
import ParentDirectoryItem from "./ParentDirectoryItem";
import { IStorageFileRead } from "./storageFileTypes";
import { DraggableItemTypes, getDirectoryCleanPath, isDirectory } from "./utils";

const StyledHeaderCell = styled(TableCell)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

interface FileManagerProps {
  currentPath: string;
  deleteFile: (file: IStorageFileRead) => Promise<void>;
  deletingFileId?: number;
  files?: IStorageFileRead[];
  isLoadingFiles?: boolean;
  moveFile: (file: IStorageFileRead, newPath: string) => Promise<void>;
  movingFileId?: number;
  refetchFiles: () => void;
  reorderMode?: boolean;
  setCurrentPath: (path: string) => void;
}

const FileManager: React.FC<FileManagerProps> = ({
  currentPath,
  deleteFile,
  deletingFileId,
  files,
  isLoadingFiles,
  moveFile,
  movingFileId,
  refetchFiles,
  reorderMode = false,
  setCurrentPath,
}) => {
  const { t } = useTranslation();
  const [[, drop]] = useMultiDrop({ accept: DraggableItemTypes.File });

  // Internal copy of files to allow for sorting
  const [internalFiles, setInternalFiles] = useState(files ?? []);

  // Whenever files change, update the internal copy
  useEffect(() => setInternalFiles(files ?? []), [files]);

  const findFile = useCallback(
    (id: string) => {
      const file = internalFiles?.find((f) => f.id.toString() === id)!;

      return {
        file,
        index: internalFiles.indexOf(file),
      };
    },
    [internalFiles],
  );

  const handleDelete = useCallback(
    async (file: IStorageFileRead) => {
      try {
        await deleteFile(file);
        enqueueSnackbar(t("File deleted successfully"), { variant: "success" });
        refetchFiles();
      } catch (error) {
        enqueueSnackbar(t("File deletion failed"), { variant: "error" });
      }
    },
    [deleteFile, refetchFiles, t],
  );

  const handleEnterDirectory = useCallback(
    (directory: IStorageFileRead) => setCurrentPath(getDirectoryCleanPath(directory.user_fullpath)),
    [setCurrentPath],
  );

  const handleDownload = useCallback(
    async (file: IStorageFileRead) => {
      try {
        let path = file.public_presigned_url;
        if (!path.startsWith("http://") && !path.startsWith("https://")) {
          path = `/api/v1${path}`;
        }
        window.open(path, "_blank");
      } catch (error) {
        enqueueSnackbar(t("File download failed"), { variant: "error" });
      }
    },
    [t],
  );

  const hasFiles = internalFiles && internalFiles.length > 0;

  return (
    <TableContainer component={Paper} sx={{ flex: 1 }} variant={"outlined"}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeaderCell>{t("fileManager.headings.name")}</StyledHeaderCell>
            <StyledHeaderCell>{t("fileManager.headings.size")}</StyledHeaderCell>
            <StyledHeaderCell>{t("fileManager.headings.type")}</StyledHeaderCell>
            <StyledHeaderCell>{t("fileManager.headings.tags")}</StyledHeaderCell>
            <StyledHeaderCell>{t("fileManager.headings.lastModified")}</StyledHeaderCell>
            <StyledHeaderCell>{t("fileManager.headings.indexStatus")}</StyledHeaderCell>
            <StyledHeaderCell></StyledHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody ref={drop}>
          {currentPath && (
            <ParentDirectoryItem
              currentPath={currentPath}
              findFile={findFile}
              moveFile={moveFile}
              setCurrentPath={setCurrentPath}
            />
          )}
          {isLoadingFiles ? (
            <TableRow>
              <TableCell colSpan={7}>{t("loading")}</TableCell>
            </TableRow>
          ) : hasFiles ? (
            internalFiles.map((file) =>
              isDirectory(file.user_fullpath) ? (
                <DirectoryItem
                  canDrag={reorderMode}
                  deleteDirectory={handleDelete}
                  directory={file}
                  enterDirectory={handleEnterDirectory}
                  findFile={findFile}
                  isLoading={deletingFileId === file.id || movingFileId === file.id}
                  moveFile={moveFile}
                  key={file.user_fullpath}
                />
              ) : (
                <FileItem
                  canDrag={reorderMode}
                  downloadFile={handleDownload}
                  deleteFile={handleDelete}
                  file={file}
                  findFile={findFile}
                  isLoading={deletingFileId === file.id || movingFileId === file.id}
                  key={file.user_fullpath}
                />
              ),
            )
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {t("fileManager.noFiles")}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileManager;
