import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { TagsList } from "@features/tags/TagsList";
import { Delete as DeleteIcon, Download as DownloadIcon, InsertDriveFileOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton, Stack, styled, TableCell, TableRow, Typography } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import { useMultiDrag, usePreview } from "react-dnd-multi-backend";
import { useTranslation } from "react-i18next";
import EditFileFormDialog from "./EditFileFormDialog";
import { IStorageFileRead } from "./storageFileTypes";
import { DraggableItemTypes, formatFileSize, getItemName } from "./utils";

interface StyledRowProps {
  isDragging: boolean;
  isLoading: boolean;
  canDrag: boolean;
}

const StyledRow = styled(TableRow)<StyledRowProps>(({ isDragging, isLoading, canDrag }) => ({
  backgroundColor: isLoading ? "rgba(0, 0, 0, 0.04)" : "inherit",
  cursor: canDrag ? (isDragging ? "grab" : "grabbing") : "default",
  opacity: isDragging ? 0.5 : 1,
  ":active": {
    cursor: canDrag ? "grabbing" : "default",
  },
}));

const StyledCell = styled(TableCell)(() => ({
  maxWidth: 240,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

interface FileItemProps {
  canDrag?: boolean;
  deleteFile: (file: IStorageFileRead) => void;
  downloadFile: (file: IStorageFileRead) => void;
  file: IStorageFileRead;
  findFile: (id: string) => { file: IStorageFileRead; index: number };
  isLoading?: boolean;
}

const FileItem: React.FC<FileItemProps> = memo(
  ({
    canDrag = false,
    deleteFile: deleteFileProps,
    downloadFile: downloadFileProps,
    file,
    findFile,
    isLoading = false,
  }) => {
    const { can } = useAuthorization();
    const { t } = useTranslation();
    const preview = usePreview();

    const deleteFile = useCallback(() => deleteFileProps(file), [deleteFileProps, file]);
    const downloadFile = useCallback(() => downloadFileProps(file), [downloadFileProps, file]);

    const statusColor = useMemo(() => {
      switch (file.index_status) {
        case "QUEUED":
          return "info.main";
        case "PENDING":
          return "warning.main";
        case "COMPLETED":
          return "success.main";
        case "FAILED":
          return "error.main";
        default:
          return "text.primary";
      }
    }, [file.index_status]);

    const originalIndex = useMemo(() => findFile(file.id.toString()).index, [file.id, findFile]);

    const canUpdateFile = can("UPDATE", "FILE");

    const [[{ isDragging }, drag]] = useMultiDrag({
      canDrag: !isLoading && canUpdateFile && canDrag,
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
      item: { id: file.id.toString(), originalIndex },
      type: DraggableItemTypes.File,
    });

    return (
      <StyledRow
        hover
        isDragging={isDragging}
        isLoading={isLoading}
        canDrag={!isLoading && canUpdateFile && canDrag}
        ref={(elem) => (canUpdateFile ? drag(elem) : null)}
        style={{
          ...(preview.display &&
          preview.itemType === DraggableItemTypes.File &&
          (preview.item as any).id === file.id.toString()
            ? preview.style
            : undefined),
        }}
      >
        <StyledCell>
          <Stack alignItems="center" direction="row" flexWrap="nowrap" gap={1}>
            {isLoading ? <CircularProgress size={24} /> : <InsertDriveFileOutlined />}
            <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {getItemName(file.user_fullpath)}
            </Typography>
          </Stack>
        </StyledCell>
        <StyledCell>
          <Typography variant="body2">{formatFileSize(file.size)}</Typography>
        </StyledCell>
        <StyledCell>
          <Typography variant="body2">{file.extension}</Typography>
        </StyledCell>
        <StyledCell>
          <Typography variant="body2">
            <TagsList tags={file.tags} />
          </Typography>
        </StyledCell>
        <StyledCell>
          <Typography variant="body2">{new Date(file.updated_at).toLocaleString()}</Typography>
        </StyledCell>
        <StyledCell sx={{ color: statusColor }}>
          <Typography variant="body2">{t(file.index_status)}</Typography>
        </StyledCell>
        <StyledCell>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
            <IconButton onClick={downloadFile}>
              <DownloadIcon />
            </IconButton>
            {can("UPDATE", "FILE") && can("DELETE", "FILE") && (
              <>
                <EditFileFormDialog file={file} />
                <IconButton onClick={deleteFile}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </Stack>
        </StyledCell>
      </StyledRow>
    );
  },
);

export default FileItem;
