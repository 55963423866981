import { useGetNoteQuery } from "@app/services/appApi";
import { INoteRead } from "@features/note/noteTypes";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import { IChatSemanticSearchToolReturn } from "../../app/services/apiTypes";
import NoteViewer from "./NoteViewer";
import PdfViewer from "./PDFViewer";
import ResourceViewerHeader from "./ResourceViewerHeader";

interface ResourceViewerModalProps {
  note?: INoteRead;
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  resource?: IChatSemanticSearchToolReturn;
}

const ResourceViewerModal = ({ note: noteFromProps, open, onClose, resource }: ResourceViewerModalProps) => {
  const { t } = useTranslation();

  const {
    data: noteFromResource,
    isLoading: isLoadingNoteFromResource,
    isError: isErrorNoteFromResource,
  } = useGetNoteQuery(resource?.src_ref_id ?? "", { skip: !resource || resource.src_ref_type !== "NOTE" });

  const isLoading = isLoadingNoteFromResource;
  const isError = isErrorNoteFromResource;
  const isNote = !!noteFromProps || (resource && resource.src_ref_type === "NOTE");
  const note = noteFromProps ?? noteFromResource;
  const isPdf = resource && resource.src_ref_type === "STORAGE_FILE";

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "90%",
          maxWidth: "md",
          maxHeight: "90%",
          backgroundColor: "white",
          borderRadius: 1,
        }}
      >
        <ResourceViewerHeader note={note} onClose={onClose} resource={resource} />
        {isLoading ? (
          <CircularProgress />
        ) : isError ? (
          <Typography variant="body1" color="error">
            {t("resourceViewerModal.error")}
          </Typography>
        ) : isNote && note ? (
          <NoteViewer note={note} />
        ) : (
          isPdf && resource && <PdfViewer resource={resource} />
        )}
      </Box>
    </Modal>
  );
};

export default ResourceViewerModal;
