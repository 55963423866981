import ResourceViewerModal from "@features/viewer/ResourceViewerModal";
import { Box } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IChat, IChatSemanticSearchToolReturn, ToolType } from "../../../app/services/apiTypes";
import { ChatMessage } from "./ChatMessage";

interface ChatMessageListProps {
  chat?: IChat;
}

export default function ChatMessageList({ chat }: ChatMessageListProps) {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);

  const [openedResult, setOpenedResult] = useState<IChatSemanticSearchToolReturn>();

  const messages = useMemo(() => chat?.messages ?? [], [chat?.messages]);

  // Scroll to the bottom of the chat history whenever a new message is added
  useEffect(() => {
    ref.current?.scrollTo({
      left: 0,
      top: ref.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages.length]);

  const openCitation = useCallback(
    (citationId: string) => {
      const tools = messages
        .filter((message) => message.author_type === "tool")
        .flatMap((message) => message.used_tools);

      const returns = tools
        .filter((tool) => tool.type === ToolType.SemanticSearch && tool.return && tool.return.length > 0)
        .flatMap((tool) => tool.return);

      const citation = returns.find((r) => r.citation_id === citationId);

      if (!citation) {
        enqueueSnackbar(t("assistant.chatMessage.citationNotFound"), { variant: "error" });
        return;
      }

      setOpenedResult(citation);
    },
    [messages, t],
  );

  return (
    <>
      <Box
        ref={ref}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          gap: 2,
          px: {
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
          },
          py: 2,
        }}
      >
        {messages.map((chatMessage, index) => (
          <ChatMessage
            key={index}
            chatMessage={chatMessage}
            openCitation={openCitation}
            setOpenedResult={setOpenedResult}
          />
        ))}
      </Box>
      <ResourceViewerModal open={!!openedResult} onClose={() => setOpenedResult(undefined)} resource={openedResult} />
    </>
  );
}
